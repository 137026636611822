import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {

    const scrollToTop = () => {
        window.scrollTo(0, 0);
    };

    return(
        <footer id='footer'>
            <div className='footer-top'>
                <div className='container'>
                    <div className='row'>
                        <div className="col-lg-3 col-md-6 footer-links">
                            <h3 className="white-text">MobiPay</h3>
                            <p className="white-text">We have been providing modern, robust and flexible payment technologies since 2010.</p>
                            <div className="social-links mt-3">
                                <Link to="https://twitter.com/Mobipay_Namibia" className="twitter"><i className="bi bi-twitter"></i></Link>
                                <Link to="https://www.facebook.com/mobipay" className="facebook"><i className="bi bi-facebook"></i></Link>
                                <Link to="https://www.instagram.com/mobipay_namibia" className="instagram"><i className="bi bi-instagram"></i></Link>
                                <Link to="https://www.linkedin.com/company/mobipay/" className="linkedin"><i className="bi bi-linkedin"></i></Link>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-6 footer-links">
                            <h4>Useful Links</h4>
                            <ul>
                                <li><i className="bi bi-chevron-right"></i> <Link to="/" onClick={scrollToTop}>Home</Link></li>
                                <li><i className="bi bi-chevron-right"></i> <Link to="/about" onClick={scrollToTop}>About</Link></li>
                                <li><i className="bi bi-chevron-right"></i> <Link to="/#services" onClick={scrollToTop}>Products & Services</Link></li>
                                <li><i className="bi bi-chevron-right"></i> <Link to="/blogs" onClick={scrollToTop}>Blogs</Link></li>
                                <li><i className="bi bi-chevron-right"></i> <Link to="/vacancies" onClick={scrollToTop}>Vacancies</Link></li>
                                <li><i className="bi bi-chevron-right"></i> <a href="https://www.mobipay.com.na/assets/uploads/ISMS-012.pdf" target="_blank" rel="noopener noreferrer">Data Privacy Policy</a></li>
                                <li><i className="bi bi-chevron-right"></i> <a href="https://www.mobipay.com.na/assets/uploads/ISMS-001-ISMS-Policy.pdf" target="_blank" rel="noopener noreferrer">Information Security Policy</a></li>
                            </ul>
                        </div>

                        <div className="col-lg-3 col-md-6 footer-links">
                            <h4>Products & Services</h4>
                            <ul>
                                <li><i className="bi bi-chevron-right"></i> <Link to="/mobiid" onClick={scrollToTop}>MobiID</Link></li>
                                <li><i className="bi bi-chevron-right"></i> <Link to="/mobibanking" onClick={scrollToTop}>MobiBanking</Link></li>
                                <li><i className="bi bi-chevron-right"></i> <Link to="/mobifleet" onClick={scrollToTop}>MobiFleet</Link></li>
                                <li><i className="bi bi-chevron-right"></i> <Link to="/mobivend" onClick={scrollToTop}>MobiVend</Link></li>
                                <li><i className="bi bi-chevron-right"></i> <Link to="/aws" onClick={scrollToTop}>Amazon Web Services (AWS)</Link></li>
                                <li><i className="bi bi-chevron-right"></i> <Link to="/mobiloyalty" onClick={scrollToTop}>MobiLoyalty</Link></li>
                            </ul>
                        </div>

                        <div className="col-lg-3 col-md-6 footer-contact">
                            <h4>Contact Us</h4>
                            <p>
                                47 Feld Street,<br/>
                                Windhoek, 10005<br/>
                                Namibia <br/><br/>
                                <strong>Phone:</strong> +264 83 323 6624<br/>
                                <strong>Email:</strong> info@mobipay.com.na<br/>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
